/* ELEMENTS */

@import '_mixins/link';

a {
  @include link;
}
a:active {
  @include link--active;
}

/* MODULES */

.row-flex {
    display: flex;
    flex-direction: row;
}

.row-flex-center {
    align-items: center;
    justify-content: center;
}

.col-flex {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.col-flex-center {
    align-items: center;
}

.col-flex-v-center {
    justify-content: center;
}

.flex-item {
    flex: 1;
}

.giant-button {
    padding: 20px;
    width: 250px;
    height: 250px;
    text-decoration: none !important;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.btn-cite {
    padding:0 8px;
    margin-top: -4px;
    margin-bottom: 0px;
}

@import 'nested-list-group';
