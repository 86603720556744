.dropdown-spacer-lg .well {
    position: relative;
    .preview-tree-btn-wrapper {
        position: absolute;
        left: -175px;
        border-bottom: 3px solid gray;
    }
}

.category-gray {
  font-weight: bold;
  color: #3E3E3E;
}

.tab-gray {
  background-color: #f5f5f5;
  border-color: #cccccc;
  white-space: normal;
}

.citation-gray {
  // display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  background-color: #f5f5f5;
  border: 1px solid #CCCCCC;
  padding-left: 8px; 
  padding: 12px 12px; 
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0px;
  width:100%; 
  display: flex; 
  justify-content: space-between; 
}

.citation-blue {
  background: #EEF9FC; 
  padding-left: 20px; 
  padding-right: 30px; 
  padding-top: 10px; 
  padding-bottom: 10px; 
  border: 1px solid #cccccc;
}

.published-green {
  color: white; 
  border: 1px solid #158600; 
  background-color: #1cb500; 
  padding-left: 10px; 
  padding-right: 10px; 
  padding-top: 7px; 
  padding-bottom: 7px;
}

.unpublished-grey {
  color: white; 
  border: 1px solid; 
  background-color: #525151; 
  padding-left: 10px; 
  padding-right: 10px; 
  padding-top: 7px; 
  padding-bottom: 7px;
}

.category-blue {
  font-weight: bold;
  color: #1568C9;
}

.tab-blue {
  background-color: #C4D9F2;
  border-color: #1568C9;
  white-space: normal;
}
.tab-cell {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
}

.category-teal {
  font-weight: bold;
  color: #43A59D;
}

.tab-teal {
  background-color: #CAE9E6;
  border-color: #43A59D;
  white-space: normal;
}

.category-yellow {
  font-weight: bold;
  color: #B59300;
}

.tab-yellow {
  background-color: #ECE4BF;
  border-color: #B59300;
  white-space: normal;
}

.category-orange {
  font-weight: bold;
  color: #BD5717;
}

.tab-orange {
  background-color: #EBCCB9;
  border-color: #BD5717;
  white-space: normal;
}

.category-purple {
  font-weight: bold;
  color: #4B3181;
}

.tab-purple {
  background-color: #C8C0D9;
  border-color: #4B3181;
  white-space: normal;
}

.category-light-blue {
  font-weight: bold;
  color: #56C0E0;
}

.tab-light-blue {
  background-color: #CCECF6;
  border-color: #56C0E0;
  white-space: normal;
}

.tab-danger {
  background-color: #f2dede;
  border-color: #a94442;
  border-width: medium;
  color: #a94442;
  white-space: normal;
}
.tab-danger:hover {
  color: #a94442;
}
.tab-danger:focus {
  color: #a94442;
}

td {
  vertical-align: top;
}
.license-element {
  display: flex; 
  flex-direction: row; 
  align-items: center;
}
