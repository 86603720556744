/*
Footer

The website footer. Project customization should be minimal.

Markup: c-footer.twig.html

Styleguide Components.Footer
*/

.c-footer {
  /* TODO: Use global custom property when available */
  --line-height: 1.5; /* value (not unit) from Bootstrap via `body` */
  --min-line-count: 2;

  /* The minimum height of the footer should be 2 lines of text, plus padding */
  box-sizing: content-box;
  /* min-height: calc(var(--line-height) * var(--min-line-count) * 1em); */

  /* Center content vertically (assuming unknown height) */
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* FAQ: Value was manually calculated, then rounded to nearest multiple of 5
          i.e. `orig. value 12px` + `( total vertical padding of content / 2 )`
          (vertical padding was added via `style` attributes on CMS markup) */
  padding-top: 20px;
  padding-bottom: 20px;

  color: rgb(255, 255, 255);
  background-color: rgb(51, 51, 51);

  font-size: 12px;
  text-align: center;
}
/* TODO: Use "custom media query" for each standard Bootstrap media query */
@media only screen and (max-width: 640px) {
  .c-footer {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media only screen and (min-width: 641px) and (max-width: 767px) {
  .c-footer {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .c-footer {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .c-footer {
    padding-left: 12.5%;
    padding-right: 12.5%;
  }
}
@media only screen and (min-width: 1200px) {
  .c-footer {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.nsflogo {
  height: 120px;
  margin-block: -14px; /* to remove extra space within image around content */
}
