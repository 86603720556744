.nco-scheduler-component {
    .nco-scheduler-list {
        table th {
            font-size: 1.1em;
            border: 1px solid gray;
            padding: 5px;
            background: white;
        }
        table td {
            border: 1px solid gray;
            border-top: none;
            padding: 5px;
            margin-bottom: 5px;
            //background: #eee;
        }
	table tr:nth-child(odd) {
	    background: #eee;
	}
	table tr:nth-child(even) {
	    background: #ddd;
	}
        .date-start .date-end {
            width: 7%;
        }
        .event-fac {
            width: 7%;
        }
        .event-title-prj {
            width: 29%;
        }
        .event-desc {
            width: 37%;
        }
        .event-action {
            width: 13%;
        }
        .a-pi .a-fac .a-payload {
            display: block;
	    margin-top: 20px;
	    margin-bottom: 5px;
	}
	.nsf {
	    margin-top: 20px;
	    margin-bottom: 10px;
	    font-style: italic;
	}
	.event-title {
	    margin-bottom: 25px;
	    font-weight: 600;
	}
	.project-title {
	    margin-bottom: 5px;
	    font-size: 0.9em;
	    font-style: italic;
	}
	.pi-name {
	    font-style: italic;
	}
    }
}
