.nco-scheduler-component {
    .nco-scheduler-filters {
        .filter {
            width: 20%;
            display: inline-block;
            padding: 0px 10px;
        }
        .sort-title {
            display: inline-block;
        }
        .sort {
            width: 20%;
            display: inline-block;
            padding: 0px 10px;
        }
    }
}
