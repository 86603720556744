@import url("./variables.css");

body, html {
  background-color: #ffffff;
}

body.navbar-fixed {
  padding-top: 73px;
}

#sitewide_search {
  /*margin-top: 0.75em;*/
}

.cms-toolbar-expanded .navbar-ds.navbar-fixed-top {
  top: 29px;
}

.slick-prev {
  left:0px;
}
.slick-next {
  right:10px;
}
.slick-prev, .slick-next {
  line-height: 2em;
  font-size: 2em;
  z-index: 1500;

}

.slick-prev:before, .slick-next:before {
    color: #d3d3d3;
    font-size: 2em;
}

.slick-prev:before {
  content: '<';
}

.slick-next:before {
  content: '>';
}
.modal-full .modal-dialog {
  width: 100%;
  min-height:100vh;
  padding: 0;
  margin: 0;
}

.modal-full .modal-content {
  min-height:100vh;

}

.img-responsive.img-preview {
  max-height: calc(100vh - 225px);
  margin: auto;
}

.ef-site-title a {
  text-decoration: none;
  color: #333;
}

.ef-site-title a:hover {
  color: #444;
}

.ef-site-title a h1 {
  margin: 0;
  line-height: 0.8;
}

.site-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5em;
  background-image: url(../images/Map-BG.jpg);
  background-size: cover;
  min-height: 90px;
  flex-wrap: wrap;
}

.site-banner-ef {
  height: unset;
  padding: 1.5em 5em;
}

.site-banner img {
  width: 450px;
}

.site-banner-right {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.social-media-list {
  list-style: none;
  padding: 0;
  margin: 0 50px 0 0;
}

.social-media-list li {
    display: inline-block;
}

.social-media-list img {
  width: 25px;
  height: 25px;
}

.site-banner-right .btn {
  padding: 2px 8px;
}

@media (max-width: 1025px) {
  .site-banner > * {
    margin: auto;
  }
  .social-media-list img {
    width: 25px;
    height: 25px;
  }
  .site-banner-left img {
    width: 300px;
  }
  .site-banner-right {
    flex-wrap: wrap;
  }
  .site-banner-right > * {
    margin: auto;
  }
}

.tagline {
  color: #136ac9;
  text-transform: uppercase;
}

.wf-active {
  font-family: futura-pt, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tagline h2 {
  font-weight: 400;
  font-size: 18px;
  max-width: 421px;
}

.wf-active .tagline h2 {
  font-size: 20px;
}

@media (min-width: 768px) {
  .tagline {
    text-align: right;
  }

  .tagline h2 {
    float: right;
    margin: 0;
  }
}

@media (max-width: 767px) {
  .tagline {
    text-align: center;
  }
}

.navbar-ds {
  font-stretch: condensed;
  font-family: futura-pt, "HelveticaNeue-CondensedBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #525151;
  padding: 0 2.5em;
}

.navbar-ds .navbar-brand {
  color: #fff;
  font-size: 1.3em;
}

.navbar-ds .navbar-nav > li > a {
  color: #fff;
  font-size: 1.3em;
}

.navbar-ds .navbar-nav > .active > a,
.navbar-ds .navbar-nav > .active > a:hover,
.navbar-ds .navbar-nav > .active > a:focus {
  color: #fff;
  font-size: 1.3em;
}

.navbar-ds .navbar-nav .dropdown-menu > li > a {
  font-size: 1.3em;
}

@media (min-width: 768px) {
.navbar-ds .navbar-nav > li > a:after {
  content: '';
  height: 4px;
  position: absolute;
  width: 0;
  bottom: -1px;
  left: 50%;
  background: #47a59d;
  -moz-transition: width 100ms ease-in-out, left 100ms ease-in-out;
  -o-transition: width 100ms ease-in-out, left 100ms ease-in-out;
  -webkit-transition: width 100ms ease-in-out, left 100ms ease-in-out;
  transition: width 100ms ease-in-out, left 100ms ease-in-out;
}

.navbar-ds .dropdown.open,
.navbar-ds.navbar-inverse .navbar-nav > .open > a,
.navbar-ds.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-ds.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #7d7d7d;
}

.navbar-ds .dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #525151;
}

.navbar-ds .navbar-nav > li > a.hl-community:after {
  background: #6caa39;
}
.navbar-ds .navbar-nav > li > a.hl-research:after {
  background: #136ac9;
}
.navbar-ds .navbar-nav > li > a.hl-ef:after {
  background: #ca463e;
}
.navbar-ds .navbar-nav > li > a.hl-learning:after {
  background: #a5a4a4;
}
}

.navbar-ds .navbar-nav > li > a:hover:after,
.navbar-ds .navbar-nav > li > a:focus:after {
  width: 100%;
  left: 0;
}

.navbar-ds .navbar-nav > .active > a,
.navbar-ds .navbar-nav > .active > a:hover,
.navbar-ds .navbar-nav > .active > a:focus {
  background: inherit;
}

.navbar-ds .navbar-nav > .active > a:after,
.navbar-ds .navbar-nav > .active > a:hover:after,
.navbar-ds .navbar-nav > .active > a:focus:after {
  width: 100%;
  left: 0;
}

.navbar-ds.navbar-inverse .navbar-nav .caret {
  display: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar-ds .navbar-nav > li > a {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 767px) {
  .global-nav {
    background-color: #eee;
    border-top: 2px solid #ddd;
  }
}

hr {
  border-width: 0.16em 0 0 0;
  border-color: #ccc;
}

h1, h2 {
  text-transform: uppercase
}

h3, h4 {
  color: #4d4e4e;
}

h4 {
  font-style: italic;
}

.headline {
  position: relative;
  margin-bottom: 20px;
}

#headline-data-depot {
  font-size:28px;
  margin-top:16px;
}

.headline::after {
  content: "___";
  color: #333;
  position: absolute;
  left: 0;
  bottom: -0.2em;
}

.hl-research, .headline-research::after {
  color: #136ac9;
}

.hl-ef, .headline-ef::after {
  color: #ca463e;
}

.hl-community, .headline-community::after {
  color: #6caa39;
}

.hl-learning, .headline-learning::after {
  color: #a5a4a4;
}

.hover-text {
  color: inherit;
}

.hover-text.hl-research:hover, .hover-text.hl-research:focus {
  color: #136ac9;
  border-color: #136ac9;
}

.hover-text.hl-ef:hover, .hover-text.hl-ef:focus {
  color: #ca463e;
  border-color: #ca463e;
}

.hover-text.hl-community:hover, .hover-text.hl-community:focus {
  color: #6caa39;
  border-color: #6caa39;
}

.hover-text.hl-learning:hover, .hover-text.hl-learning:focus {
  color: #a5a4a4;
  border-color: #a5a4a4;
}

.buttonlinks {
  background: url(../images/button.jpg) 3px 1px no-repeat;
  height: 74px;
  width: auto;
  text-align: center;
  padding: 7px;
  margin: auto;
}

.buttonlinks a:link, .buttonlinks a:visited, .buttonlinks a:active {
  font-size: 1.6em;
  color: #ffffff;
  text-decoration: none;
}

.buttonlinks a:hover {
  font-size: 1.6em;
  color: #cccccc;
  text-decoration: none;
}

#logos {
  text-align: center;
}

#logos li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

label.required:after,
.required > label:after,
.required > .checkbox > label:after {
  content: "(required)";
  font-weight: 300;
  font-size: .9em;
  color: #A94442;
}

.checkboxselectmultiple ul,
.radioselect ul {
  padding-left: 0;
  list-style: none;
}

.checkboxselectmultiple ul li label,
.radioselect ul li label {
  font-weight: normal;
  cursor: pointer;
}

.error {
  color: #A94442;
}

.box {
  background-color: #fff;
  border: 1px solid #4a4e4e;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.box-topper {
  background-size: cover;
  padding: 5px;
}

.box-topper-research {
  background-image: url('../images/Sub-Nav-Blue.jpg')
}

.box-topper-ef {
  background-image: url('../images/Sub-Nav-Red.jpg')
}

.box-topper-community {
  background-image: url('../images/Sub-Nav-Green.jpg')
}

.box-topper-learning {
  background-image: url('../images/Sub-Nav-Grey.jpg')
}

.box-content {
  padding: 10px;
  height: 160px;
  overflow: hidden;
}

.box-content h4 {
  font-style: normal;
  font-weight: bold;
}

.ds-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.ds-icon-sm {
  width: 32px;
  height: 32px;
}

.ds-icon-lg {
  width: 64px;
  height: 64px;
}

.ds-icon-cloud-disk {
  background-image: url('../images/ds-icons/Icon-Data-Depot.png');
}
.ds-icon-desktop-compass {
  background-image: url('../images/ds-icons/Icon-Disc-Workspace.png');
}
.ds-icon-portal-eye {
  background-image: url('../images/ds-icons/Icon-Recon.png');
}
.ds-icon-portal-code {
  background-image: url('../images/ds-icons/Icon-Dev-port.png');
}
.ds-icon-home {
  background-image: url('../images/ds-icons/Icon-Fac-list.png');
}
.ds-icon-desktop-calendar {
  background-image: url('../images/ds-icons/Icon-Schedule.png');
}
.ds-icon-document-bolt {
  background-image: url('../images/ds-icons/Icon-Rapid.png');
}
.ds-icon-group {
  background-image: url('../images/ds-icons/Icon-Exist-Comm.png');
}
.ds-icon-group-add {
  background-image: url('../images/ds-icons/Icon-New-Comm.png');
}
.ds-icon-toolbox {
  background-image: url('../images/ds-icons/Icon-Comm-tools.png');
}
.ds-icon-document-copy {
  background-image: url('../images/ds-icons/Icon-Train-Resources.png');
}
.ds-icon-support {
  background-image: url('../images/ds-icons/Icon-Use-Support.png');
}
.ds-icon-lightbulb {
  background-image: url('../images/ds-icons/Icon-Students.png');
}

/* apply alert-danger style for alert-error */
.alert-error {
  background-color: #F2DEDE;
  border-color: #EBCCD1;
  color: #A94442;
}

/* btn-link-alt */
.btn-link-alt {
  font-weight: bold;
  border: 2px solid #337AB7;
  text-decoration: none;
  background-color: #fff;
}

.btn-link-alt:hover,
.btn-link-alt:focus,
.btn-link-alt.focus {
  border-color: #23527c;
  color: #23527c;
}

.btn-group .btn-link-alt + .btn-link-alt,
.btn-group .btn-link-alt + .btn-group,
.btn-group .btn-group + .btn-link-alt {
    margin-left: -2px;
}

.btn-toolbar > .btn-group-data-depot-toolbar {
  margin-left:0px;
}

.callout {
  padding: 1em;
  border: 1px solid;
  margin: 20px 0;
}

.callout-default {
  background-color: #eeeeee;
  border-color: #cccccc;
}

#notification_badge {
  height: 1.8em;
  width: 1.8em;
  line-height: 1.6em;
  top: -5px;
  padding: 0;
  position: absolute;
}

.label-as-badge {
  left: 1.8em;
  border-radius: 30%;
  border: 1px solid white;
}

#notification_bell {
  font-size: 25px;
  padding-right: 10px;
}

dd {
    margin-bottom: .5em;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: none;
  opacity: 0.8;
}

button.toast-close-button {
  background-color: #cccccc;
  color: #333333;
  border: solid #efefef 1px;
}

/*#toast-container > .toast-info {
  background-image: none !important;
}

#toast-container > .toast-error {
  background-image: none !important;
}

#toast-container > .toast-success {
  background-image: none !important;
}

#toast-container > .toast-warning {
  background-image: none !important;
}*/

ul.metadata-inner-list {
  list-style:none;
}

ul.metadata-inner-list li {
  list-style:none;
}

ul.metadata-inner-list > li {
  border-bottom:1px dashed lightgray;
  margin-bottom:5px;
}

ul.metadata-inner-list > li:last-child{
  border-bottom:none;
}

table.browser-table-files-list {
    table-layout:fixed;
}

table .browser-filename-column {
    width:350px;
}

table .browser-path-column {
    width:350px;
}

table td {
    word-wrap:break-word;
}

.arrow-up {
  width: 0;
  height: 0;
  position: relative;
}

.arrow-up:before, .arrow-up:after {
  content: '';
  display: none;
  position: absolute;
  z-index: 1001;
}

.arrow-up:before {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #afafaf;
  left: -1px;
  top: 2px;
}

.arrow-up:after {
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid #ffffff;
  top: 3px;
}

.dropdown.open .arrow-up:before, .dropdown.open .arrow-up:after {
  display: block;
}

.dropdown.open .dropdown-menu.notifications{
  top:140%;
  padding: 5px 0 0 0;
}

.notifications {
  width:35em;
  left: -25em;
  white-space: normal;
}

.notifications-wrapper {
  overflow:auto;
  max-height:30em;
}

.menu-title {
  color:#337ab7;
  font-size:1.5rem;
  display:inline-block;
  margin: 0;
}

.glyphicon-circle-arrow-right {
  margin-left: 10px;
}

.notification-heading, .notification-footer  {
 padding: 2px 10px;
}

.dropdown-menu .divider {
  margin:0px 0;
  background-color: #cccccc;
}

#notification-container {
  cursor:default;
}

.item-title {
  font-size:1.3rem;
  color:#000;
}

.notification-item {
  padding:10px;
  margin-top:0px;
  border-radius:1px;
  border-bottom: 1px solid #c3c3c3;
  display: flex;
  justify-content: space-between;
}

.notification-item:hover {
  background:#f7f7f7;
}

.notification-item .dl-horizontal {
  margin:0;
  display: inline-block;
  width: 31em;
}

.notification-item .dl-horizontal dd {
  margin: 0 0 0 9em;
}

#notif-path {
  color: #909090;
  direction: rtl;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  margin: 0;
}

.notification-item .dl-horizontal dt {
  width: 7em;
}

.notif-close-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notif-close {
  display: block;
  flex-shrink: 1;
  margin: 0 auto;
}

.clear-notifs {
  cursor: pointer;
  padding: 7px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color:#337ab7;
  margin: 0;
}

.clear-notifs:hover {
  background:#f7f7f7;
}

@media (max-width: 767px) {
  .notifications {
    left: -6.5em;
    width: 28.5em;
  }
  .notification-item .dl-horizontal {
    width: 25.5em;
  }
  .notification-item .dl-horizontal div {
    display: none;
  }
  .notification-item .dl-horizontal dt {
    float: left;
  }
}

.notification-raw-message {
  overflow-wrap: anywhere;
}

#toast-container {
  position: fixed;
  bottom: 0;
  z-index: 999;
  overflow: visible !important;
}

md-toast {
  padding: 0 !important;
  margin: 8px;
}

#toast-container md-toast .custom-toast {
  display: block;
}

#toast-container md-toast .custom-toast::before {
  display: none;
}

md-toast.ng-leave {
  visibility: hidden;
}

md-toast.error .md-toast-content {
  background: #f44336;
  color: white;
}

md-toast.success .md-toast-content {
  background: #4caf50;
  color: white;
}

md-toast.warning .md-toast-content {
  background: #EEAB2B;
  color: white;
}

md-toast.info .md-toast-content {
  /* background: #4caf50; */
  color: white;
}

li .popover.right {
  width: 185px;
}

.workspace-tab-title {
  margin-top: 12px;
}

.workspace-tab {
  width: calc((100% - 20px)/7);
}

#workspace-tabs a h3 {
    color: #FFFFFF;
    display: inline-block;
    margin: auto auto auto -.7em;
    vertical-align: middle;
    line-height: normal;
    font-size: 2vw;
    font-weight: normal;
}

#workspace-tabs > .nav-tabs > li.active h3 {
    color: #000000;
}

#workspace-tabs {
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

#workspace-tabs li {
  z-index: 1;
}

#workspace-tabs a {
  background: #303030;
  padding: 0 0 0 1em;
  text-align: center;
  border-radius: 5px 5px 0 0;
  border: 1px solid rgba(8,8,8,0.6);
  border-style: solid none none solid;
  width: calc(100% - 1.1em);
  height: 3em;
  line-height: 2.5em;
}

#workspace-tabs a:hover,
#workspace-tabs a:hover::after {
  background: #525252;
}

#workspace-tabs > .nav-tabs > li.active > a,
#workspace-tabs > .nav-tabs > li.active > a::after {
  background: #fff;
}

#workspace-tabs a:focus {
  outline: 0;
}

#workspace-tabs a::after {
  content:'';
  position:absolute;
  z-index: 1;
  top: -.1em;
  right: -.5em;
  bottom: 0;
  width: 3em;
  background: #303030;
  transform: skew(20deg);
  border-radius: 0 5px 0 0;
  border: 1px solid rgba(8,8,8,0.5);
  border-style: solid solid none none;
  z-index: -1;
}

#workspace-tabs .nav-tabs {
  border-bottom: none;
}

.o-site {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.o-site__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

.o-site__body > .container-fluid {
  margin: 0 30px;
}

.o-site__footer {
  margin-top: 20px;
}

.bg-image-5ysp {
  background-image: url(../images/muhry_northamerica-blue@2x.png);
}
.bg-image-gs {
  background-image:url(../images/muhry_northamerica-Green@2x.png);
}
.feedback-modal .modal-dialog {
    max-width: 800px;
}
.feedback-modal .modal-body {
    padding: 30px;
}

@media(min-width:992px) {
  .reg-right-col {
    padding-left:75px;
  }
  .reg-left-col {
    padding-right:75px;
  }
}
